<template>
  <div
    class="plan"
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-coin"
  >
    <template v-if="myPlans && myPlans.length > 0">
      <el-card class="mb-4">
        <Heading content="My Plans" heading="h6"></Heading>
        <hr />
        <MyPlans :myPlans="myPlans"></MyPlans>
      </el-card>
      <hr />
    </template>
    <div class="d-flex" style="flex-direction:row-reverse">
      <div class="switch-date">
        <div
          @click="planDate = 'Yearly'"
          :class="{
            'switch-button': true,
            'switch-active': planDate === 'Yearly'
          }"
        >
          Yearly
        </div>
        <div
          @click="planDate = 'Monthly'"
          :class="{
            'switch-button': true,
            'switch-active': planDate === 'Monthly'
          }"
        >
          Monthly
        </div>
      </div>
    </div>
    <div class="plan-cover">
      <div class="plan-item">
        <h2>Basic</h2>
        <h5>Best for short-term crammers</h5>
        <div class="plan-fee free-text">FREE</div>
        <router-link
          class="plan-button free-button"
          style="text-decoration: none;display:block;color: white!important;width: 100%;"
          :to="{
            name: 'StudentHome'
          }"
        >
          Free forever
        </router-link>
        <div class="mb-4 text-center price-text">
          <small>&nbsp;</small>
        </div>
        <div class="plan-options">
          <b>Key Features:</b>
          <div
            class="plan-option"
            v-for="(item, index) in freeList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <span v-show="index > 8">
                <b class="logo-color mr-1">AI</b>
              </span>
              <span v-html="item"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="plan-item plan-pro">
        <h2>Pro</h2>
        <h5>Best when preparing for single exam</h5>
        <div v-show="planDate === 'Yearly'">
          <div class="plan-fee money-text">
            $29.99
          </div>
        </div>
        <div v-show="planDate === 'Monthly'">
          <div class="plan-fee money-text">
            $49.99
          </div>
        </div>
        <div
          v-if="isLogin"
          class="plan-button money-button"
          @click="getPlan('Pro', planDate)"
        >
          Get Started
        </div>
        <router-link
          v-else
          class="plan-button money-button"
          style="text-decoration: none;display:block;color: white!important;width: 100%;"
          :to="{
            name: 'StudentHome'
          }"
        >
          Get Started
        </router-link>
        <div class="mb-4 text-center price-text">
          <small>billed per user per month</small>
        </div>
        <div class="plan-options">
          <b>Key Features:</b>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b>Unlimited Access</b> to 1 Exam:
              <span
                @click="setProType('SAT')"
                :class="{ activePro: ProType === 'SAT', 'pro-type': true }"
              >
                SAT</span
              >
              /
              <span
                @click="setProType('ACT')"
                :class="{ activePro: ProType === 'ACT', 'pro-type': true }"
                >ACT</span
              >
              /
              <span
                @click="setProType('AP')"
                :class="{ activePro: ProType === 'AP', 'pro-type': true }"
                >AP (1 Subject)</span
              >
              /
              <span
                @click="setProType('PSAT')"
                :class="{ activePro: ProType === 'PSAT', 'pro-type': true }"
                >PSAT</span
              >
              /
              <span
                @click="setProType('TOEFL')"
                :class="{ activePro: ProType === 'TOEFL', 'pro-type': true }"
                >TOEFL</span
              >
              /
              <span
                @click="setProType('IELTS')"
                :class="{ activePro: ProType === 'IELTS', 'pro-type': true }"
                >IELTS</span
              >
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              100% Replica of the Bluebook/TestNav Platform
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b v-show="ProType === 'SAT'">20</b>
              <span v-show="ProType !== 'SAT'">2</span>
              Full Length SATs
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b v-show="ProType === 'ACT'">40</b>
              <span v-show="ProType !== 'ACT'">4</span>
              Full Length ACTs
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b v-show="ProType === 'AP'">20</b>
              <span v-show="ProType !== 'AP'">2</span>
              Full Length APs (Coming Soon)
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b v-show="ProType === 'PSAT'">6</b>
              <span v-show="ProType !== 'PSAT'">1</span>
              Full Length
              <span v-show="ProType === 'PSAT'">
                PSATs
              </span>
              <span v-show="ProType !== 'PSAT'">
                PSAT
              </span>
              (Coming Soon)
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b v-show="ProType === 'TOEFL'">50</b>
              <span v-show="ProType !== 'TOEFL'">2</span>
              Full Length TOEFLs (Coming Soon)
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b v-show="ProType === 'IELTS'">40</b>
              <span v-show="ProType !== 'IELTS'">2</span>
              Full Length IELTSs (Coming Soon)
            </span>
          </div>
          <div
            class="plan-option"
            v-for="(item, index) in proList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <span v-show="index > 0">
                <b class="logo-color mr-1">AI</b>
              </span>
              <span v-html="item"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="plan-item plan-max">
        <h2>Max<span class="plan-tag">Popular</span></h2>
        <h5>Best when preparing for multiple exams</h5>
        <div v-show="planDate === 'Yearly'">
          <div class="plan-fee money-text">
            $59.99
          </div>
        </div>
        <div v-show="planDate === 'Monthly'">
          <div class="plan-fee money-text">
            $99.99
          </div>
        </div>
        <div
          v-if="isLogin"
          class="plan-button money-button plan-max"
          @click="showMaxTypeDialog = true"
        >
          Get Started
        </div>
        <router-link
          v-else
          class="plan-button money-button plan-max"
          style="text-decoration: none;display:block;color: white!important;width: 100%;"
          :to="{
            name: 'StudentHome'
          }"
        >
          Get Started
        </router-link>
        <div class="mb-4 text-center price-text">
          <small>billed per user per month</small>
        </div>
        <div class="plan-options">
          <b>Key Features:</b>
          <div
            class="plan-option"
            v-for="(item, index) in maxList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <span v-show="index > 8">
                <b class="logo-color mr-1">AI</b>
              </span>
              <span v-html="item"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Pro -->
    <el-dialog
      :visible.sync="showProTypeDialog"
      :width="isPhone ? '95%' : '80%'"
      class="p-0"
    >
      <h4 slot="title">
        <div class="plans-title plan-pro-button">
          Pro Plans
        </div>
      </h4>
      <div>
        <el-card>
          <Heading content="Select Pro Plan" heading="h6"></Heading>
          <hr />
          <template v-if="unlockedProTypes && unlockedProTypes.length > 0">
            <div>
              <el-radio-group v-model="ProType">
                <el-radio
                  v-for="(type, index) in unlockedProTypes"
                  :key="index"
                  :label="type.name"
                  border
                >
                  <b class="test-color">{{ type.name }}</b>
                </el-radio>
              </el-radio-group>
            </div>
            <template v-if="ProType">
              <hr />
              <div class="plan-options">
                <b>Key Features:</b>
                <div class="plan-option">
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>
                    100% Replica of the Bluebook/TestNav Platform
                  </span>
                </div>
                <div class="plan-option">
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>
                    <b class="text-success" v-show="ProType === 'SAT'">20</b>
                    <span v-show="ProType !== 'SAT'">2</span>
                    Full Length SATs
                  </span>
                </div>
                <div class="plan-option">
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>
                    <b class="text-success" v-show="ProType === 'ACT'">40</b>
                    <span v-show="ProType !== 'ACT'">4</span>
                    Full Length ACTs
                  </span>
                </div>
                <div class="plan-option">
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>
                    <b class="text-success" v-show="ProType === 'AP'">20</b>
                    <span v-show="ProType !== 'AP'">2</span>
                    Full Length APs (Coming Soon)
                  </span>
                </div>
                <div class="plan-option">
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>
                    <b class="text-success" v-show="ProType === 'PSAT'">6</b>
                    <span v-show="ProType !== 'PSAT'">1</span>
                    Full Length
                    <span v-show="ProType === 'PSAT'">
                      PSATs
                    </span>
                    <span v-show="ProType !== 'PSAT'">
                      PSAT
                    </span>
                    (Coming Soon)
                  </span>
                </div>
                <div class="plan-option">
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>
                    <b v-show="ProType === 'TOEFL'">50</b>
                    <span v-show="ProType !== 'TOEFL'">2</span>
                    Full Length TOEFLs (Coming Soon)
                  </span>
                </div>
                <div class="plan-option">
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>
                    <b v-show="ProType === 'IELTS'">40</b>
                    <span v-show="ProType !== 'IELTS'">2</span>
                    Full Length IELTSs (Coming Soon)
                  </span>
                </div>
                <div
                  class="plan-option"
                  v-for="(item, index) in proList"
                  :key="index"
                >
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>
                    <span v-show="index > 0">
                      <b class="logo-color mr-1">AI</b>
                    </span>
                    <span v-html="item"></span>
                  </span>
                </div>
              </div>
              <hr />
              <div class="text-right">
                <div class="d-flex" style="flex-direction:row-reverse">
                  <div class="switch-date mb-0">
                    <div
                      @click="planDate = 'Yearly'"
                      :class="{
                        'switch-button': true,
                        'switch-active': planDate === 'Yearly'
                      }"
                    >
                      Yearly
                    </div>
                    <div
                      @click="planDate = 'Monthly'"
                      :class="{
                        'switch-button': true,
                        'switch-active': planDate === 'Monthly'
                      }"
                    >
                      Monthly
                    </div>
                  </div>
                </div>
                <div v-show="planDate === 'Yearly'">
                  <div class="d-flex mt-2" style="flex-direction:row-reverse">
                    <b style="font-size: 1.5rem;">
                      <span class="pro-color">$29.99</span>
                      * 12 =
                    </b>
                  </div>
                  <div
                    class="plan-fee money-text  pro-color"
                    style="font-size: 3rem;"
                  >
                    $359.88
                  </div>
                </div>
                <div v-show="planDate === 'Monthly'">
                  <div
                    class="plan-fee money-text pro-color"
                    style="font-size: 3rem;"
                  >
                    $49.99
                  </div>
                </div>
              </div>
              <hr />
              <div class="text-right">
                <el-button type="primary" @click="getProPayUrl" v-show="ProType"
                  >Pay</el-button
                >
              </div>
            </template>
          </template>
          <template v-else>
            <div class="text-center text-muted mb-4 mt-4">
              You already have all Pro permissions.
            </div>
          </template>
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <!-- Max -->
    <el-dialog
      :visible.sync="showMaxTypeDialog"
      :width="isPhone ? '95%' : '80%'"
      class="p-0"
    >
      <h4 slot="title">
        <div class="plans-title plan-max-button">
          Max Plan
        </div>
      </h4>
      <div>
        <el-card>
          <Heading content="Max Plan" heading="h6"></Heading>
          <hr />
          <template v-if="!isMax">
            <template>
              <div class="plan-options">
                <b>Key Features:</b>
                <div
                  class="plan-option"
                  v-for="(item, index) in maxList"
                  :key="index"
                >
                  <span class="icon">
                    <i class="fa fa-check"></i>
                  </span>
                  <span>
                    <span v-show="index > 8">
                      <b class="logo-color mr-1">AI</b>
                    </span>
                    <span v-html="item"></span>
                  </span>
                </div>
              </div>
              <hr />
              <div class="text-right">
                <div class="d-flex" style="flex-direction:row-reverse">
                  <div class="switch-date mb-0">
                    <div
                      @click="planDate = 'Yearly'"
                      :class="{
                        'switch-button': true,
                        'switch-active': planDate === 'Yearly'
                      }"
                    >
                      Yearly
                    </div>
                    <div
                      @click="planDate = 'Monthly'"
                      :class="{
                        'switch-button': true,
                        'switch-active': planDate === 'Monthly'
                      }"
                    >
                      Monthly
                    </div>
                  </div>
                </div>
                <div v-show="planDate === 'Yearly'">
                  <div class="d-flex mt-2" style="flex-direction:row-reverse">
                    <b style="font-size: 1.5rem;">
                      <span class="max-color">$59.99</span>
                      * 12 =
                    </b>
                  </div>
                  <div
                    class="plan-fee money-text max-color"
                    style="font-size: 3rem;"
                  >
                    $719.88
                  </div>
                </div>
                <div v-show="planDate === 'Monthly'">
                  <div
                    class="plan-fee money-text max-color"
                    style="font-size: 3rem;"
                  >
                    $99.99
                  </div>
                </div>
              </div>
            </template>
            <hr />
            <div class="text-right">
              <el-button type="primary" @click="getMaxPayUrl">
                Pay
              </el-button>
            </div>
          </template>
          <template v-else>
            <div class="text-center text-muted mb-4 mt-4">
              You have already subscribed to this plan.
            </div>
          </template>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import plansApi from "@/apis/plans";
import moment from "moment";
import baseDomainPath from "@/common/baseDomainPath";
import userApi from "@/apis/user";
import MyPlans from "@/views/profile/components/MyPlans.vue";

export default {
  components: { MyPlans },

  mixins: [],

  props: [],
  data() {
    return {
      myPlans: [],
      ProType: "SAT",
      ProTypes: ["SAT", "ACT", "AP", "PSAT", "TOEFL", "IELTS"],
      activeProTypes: [
        { name: "SAT", canUseIds: ["sat_v2_pro", "max"] },
        { name: "ACT", canUseIds: ["act_pro", "max"] }
        // { name: "AP", canUseIds: ["ap_pro", "max"] }
      ],
      interval: null,
      planDate: "Yearly",
      freeList: [
        "Limited Access to All Exams",
        "100% Replica of the Bluebook/TestNav Platform",
        "2 Full Length SATs",
        "2 Full Length ACTs",
        "2 Full Length APs (Coming Soon)",
        "1 Full Length PSAT (Coming Soon)",
        "2 Full Length TOEFLs (Coming Soon)",
        "2 Full Length IELTSs (Coming Soon)",
        "Immediate Scoring",
        "Interactive Answer Explanations",
        "Time Tracking & Proctoring",
        "Performance Analysis",
        "Intelligent Flash Cards"
      ],
      proList: [
        "Immediate Scoring",
        "<b>Unlimited</b> Interactive Answer Explanations ",
        "Time Tracking & Proctoring",
        "Performance Analysis",
        "Intelligent Flash Cards",
        "Smart Score Projector",
        "Focused Practice Drills",
        "Powered Study Plan"
      ],
      maxList: [
        "<b>Unlimited Access</b> to All Exams",
        "100% Replica of the Bluebook/TestNav Platform",
        "<b>20</b> Full Length SATs",
        "<b>40</b> Full Length ACTs",
        "<b>20</b> Full Length APs (Coming Soon)",
        "<b>6</b> Full Length PSATs (Coming Soon)",
        "<b>50</b> Full Length TOEFLs (Coming Soon)",
        "<b>40</b> Full Length IELTSs (Coming Soon)",
        "Immediate Scoring",
        "<b>Unlimited</b> Interactive Answer Explanations ",
        "Time Tracking & Proctoring",
        "Performance Analysis",
        "Intelligent Flash Cards",
        "Smart Score Projector",
        "Focused Practice Drills",
        "Powered Study Plan"
      ],
      moment,
      langIsEn: "",
      isTW: false,
      showProTypeDialog: false,
      showMaxTypeDialog: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    ...mapState("user", ["token", "lang", "profile"]),
    isLogin() {
      // TODO: 應該還要檢查 token 是否合法？
      return this.token !== "";
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    unlockedProTypes() {
      let arr = [];
      this.activeProTypes.forEach(type => {
        let canUse = false;
        this.myPlans.forEach(myPlan => {
          if (type.canUseIds.indexOf(myPlan.subscription_plan.key_name) > -1) {
            canUse = true;
          }
        });
        if (!canUse) {
          arr.push(type);
        }
      });
      return arr;
    },
    isMax() {
      let isTrue = false;
      this.myPlans.forEach(myPlan => {
        if (myPlan.subscription_plan.key_name === "max") {
          isTrue = true;
        }
      });
      return isTrue;
    },
    userId() {
      return this.getProfile.id;
    }
  },
  watch: {
    userId(userId) {
      if (userId && this.isLogin) {
        this.getUserPlans(userId);
      }
    }
  },

  mounted() {
    let index = 0;
    this.interval = setInterval(() => {
      index = index + 1;
      if (index > this.ProTypes.length - 1) {
        index = 0;
      }
      this.ProType = this.ProTypes[index];
    }, 2000);

    if (this.userId && this.isLogin) {
      this.getUserPlans(this.userId);
    }
  },

  methods: {
    upgrade() {
      this.showProTypeDialog = false;
      this.showMaxTypeDialog = false;
    },
    async getUserPlans(id) {
      const res = await userApi.getUserPlans(id, {
        status: "active"
      });
      this.myPlans = res;
    },
    getMaxPayUrl() {
      let proData = {
        subscription_plan_key_name: "max",
        type: "one_time",
        interval: null,
        interval_count: 1
      };
      let id = null;
      if (this.planDate === "Monthly") {
        proData.interval = "month";
      }
      if (this.planDate === "Yearly") {
        proData.interval = "year";
      }
      this.showMaxTypeDialog = false;
      this.getPayUrl(proData);
    },
    getProPayUrl() {
      let proData = {
        subscription_plan_key_name: null,
        type: "one_time",
        interval: null,
        interval_count: 1
      };
      if (this.planDate === "Monthly") {
        proData.interval = "month";
      }
      if (this.planDate === "Yearly") {
        proData.interval = "year";
      }
      if (this.ProType === "SAT") {
        proData.subscription_plan_key_name = "sat_v2_pro";
      }
      if (this.ProType === "ACT") {
        proData.subscription_plan_key_name = "act_pro";
      }
      if (this.ProType === "AP") {
        proData.subscription_plan_key_name = "ap_pro";
      }
      this.showProTypeDialog = false;
      this.getPayUrl(proData);
    },
    async getPayUrl(data) {
      if (data) {
        this.loading = true;
        const res = await plansApi.getPayUrl({
          ...data,
          success_url: `${baseDomainPath}student/profile?active=plans`
        });
        if (res.url) {
          window.location.href = res.url;
        } else {
          this.loading = false;
        }
      }
    },

    getPlan(planType, planDate) {
      this.showProTypeDialog = true;
      this.setProType(null);
    },
    setProType(ProType) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.ProType = ProType;
    }
  }
};
</script>
<style scoped lang="scss">
.plan {
  text-align: left;
}
.switch-date {
  border: 1px solid #ccc;
  border-radius: 1rem;
  width: 180px;
  display: flex;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.switch-button {
  border-radius: 0.5rem;
  width: 50%;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
.switch-active {
  background-color: var(--themeColor);
  color: white;
}
.plan-cover {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 1rem;
}
.plan-item {
  padding: 1rem 1.5rem;
  flex: 1;
}
.plan-item:not(:last-child) {
  border-right: 1px solid #ccc;
}
.plan-item h5 {
  font-size: 1rem;
  max-height: 1rem;
}
.plan-fee {
  font-size: 4rem;
  font-weight: 700;
}
.free-text {
  color: #01b884;
}
.money-text {
  color: var(--themeColor);
}
.plan-button {
  margin-top: 1rem;
  // margin-bottom: 3rem;
  color: white !important;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--themeColor);
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-image 0.5s ease;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s, transform 0.3s;
}
.plan-button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}
.plan-options {
  // color: var(--themeColor);
  // line-height: 28px;
  font-size: 14px;
}
.plan-option {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin: 10px 0;
}
.plan-option .icon {
  font-weight: 700;
  margin-right: 0.8rem;
}
.free-button {
  background-color: #01b884;
}
.money-button {
  background-color: #fa17e3;
}

.pro-color {
  color: #fa17e3;
}
.max-color {
  color: #1f7cff;
}
.plan-max.money-button {
  background-color: #1f7cff;
}
.contact-button {
  background-color: var(--themeColor);
}
@media screen and (max-width: 768px) {
  .plan-container {
    max-width: 100%;
    padding: 0 15px 20px 15px;
  }
  .plan-cover {
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  .plan-item {
    width: 35rem !important;
    word-break: normal !important;
    // border: 1px solid #ccc;
    // margin-bottom: 2rem;
    // border-radius: 1rem;
  }
  .plan-option {
    font-size: 1rem;
    line-height: 2rem;
    margin: 2px 0;
  }
  .switch-date {
    width: 100%;
  }
}
</style>
<style scoped>
.plan-pro.plan-item h2,
.plan-pro.plan-item .money-text {
  color: #fa12e3;
}
.plan-pro .contact-button,
.plan-pro-button {
  background-color: #fa12e3;
}
.plan-max.plan-item h2,
.plan-max.plan-item .money-text {
  color: #1f7cff;
}
.plan-max .contact-button,
.plan-max-button {
  background-color: #1f7cff;
}
.plan-tag {
  font-size: 1rem;
  color: white;
  display: inline-block;
  line-height: 2rem;
  padding: 0 1rem;
  margin-left: 0.5rem;
  border-radius: 1rem;
}
.plan-max .plan-tag {
  background-color: #1f7cff;
}
.plans-title {
  /* background-color: var(--themeColor); */
  font-size: 1rem;
  color: white;
  display: inline-block;
  line-height: 2rem;
  padding: 0 1rem;
  margin-left: 0.5rem;
  border-radius: 1rem;
}
.price-text {
  color: #7f76b3;
}
.pro-type {
  cursor: pointer;
}
.pro-type:hover,
.pro-type.activePro {
  text-decoration: underline;
  color: #fa12e3;
  font-weight: 700;
}

::v-deep .el-dialog__body {
  padding-top: 0;
  /* padding-bottom: 0; */
}
</style>
